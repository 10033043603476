// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/AboutUs.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-b-2-b-js": () => import("./../../../src/templates/b2b.js" /* webpackChunkName: "component---src-templates-b-2-b-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/ContactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-customer-information-js": () => import("./../../../src/templates/CustomerInformation.js" /* webpackChunkName: "component---src-templates-customer-information-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-impressum-js": () => import("./../../../src/templates/Impressum.js" /* webpackChunkName: "component---src-templates-impressum-js" */),
  "component---src-templates-institutional-clients-js": () => import("./../../../src/templates/InstitutionalClients.js" /* webpackChunkName: "component---src-templates-institutional-clients-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/PrivacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-private-clients-js": () => import("./../../../src/templates/PrivateClients.js" /* webpackChunkName: "component---src-templates-private-clients-js" */),
  "component---src-templates-why-smartwealth-js": () => import("./../../../src/templates/WhySmartwealth.js" /* webpackChunkName: "component---src-templates-why-smartwealth-js" */)
}

